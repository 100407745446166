<template>
  <v-card>
    <v-layout>
      <v-main style="min-height: 100vh">
        <v-container v-if="userAuthenticated" style="max-width: 100px">
          <v-row>
            <v-col cols="2">
              <TheMenu class="row-border" :region="selectedRegion" />
            </v-col>
            <v-col cols="10">
              <v-row class="mt-0 mx-3 row-border bg-white rounded-xl px-3 py-1">
                <v-col cols="6" class="d-flex align-center">
                  <v-img
                    style="max-width: 150px; max-height: 45px"
                    max-width="auto"
                    src="./assets/logo.png"
                  ></v-img>
                </v-col>
                <v-col cols="6" class="d-flex justify-end align-center">
                  <v-select
                    id="region"
                    class="mr-3 regionPicker"
                    label="Region"
                    density="compact"
                    v-model="selectedRegion"
                    :items="regions"
                    @update:modelValue="changeRegion"
                  ></v-select>
                </v-col>
              </v-row>
              <router-view :region="selectedRegion" :api="apiRequest" />
            </v-col>
          </v-row>
        </v-container>
        <v-row v-else id="login" class="ma-0 pa-0">
          <router-view :region="selectedRegion" :api="apiRequest" />
        </v-row>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import TheMenu from "./components/layout/TheMenu";
import { auth } from "./services/firebase.js";

export default {
  name: "App",
  components: {
    TheMenu,
  },
  data() {
    return {
      selectedRegion: "",
      regions: ["mk", "bg", "rs", "hr", "ba", "al", "ks", "ca"],
      userIsLoggedIn: false,
      userEmailVerified: false,
    };
  },
  mounted() {
    this.selectedRegion = this.$route.params.region || "mk";
  },
  created() {
    auth.onAuthStateChanged((user) => {
      this.userIsLoggedIn = user !== null;
      this.userEmailVerified = user ? user.emailVerified : false;
    });
  },
  methods: {
    changeRegion() {
      if (
        this.$route.name === "profile" ||
        this.$route.name === "login" ||
        this.$route.name === "verify"
      ) {
        return;
      }
      const newPath = `/${this.selectedRegion}${this.$route.path.substring(
        this.$route.path.indexOf("/", 1)
      )}`;
      this.$router.push(newPath);
    },
  },
  computed: {
    userAuthenticated() {
      return this.userIsLoggedIn && this.userEmailVerified;
    },
    apiRequest() {
      return "https://api.statistika.ai/api/v1/";
    },
  },
};
</script>

<style>
.v-list-item-title {
  text-transform: capitalize !important;
}
div#login {
  background-image: url("/src/img/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%; /* Ensure your div has a width and height */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  height: 100%; /* or any other height you prefer */
}
@media (min-width: 960px) {
  .v-container {
    max-width: 1600px !important;
  }
}

main.v-main {
  background-color: #e9ebf4;
}

.v-select.regionPicker {
  max-width: 100px;
}

.regionPicker .v-select__selection-text {
  text-transform: uppercase !important;
}

/* sub menu */
.v-list-group__items .v-list-item {
  padding-inline-start: 25px !important;
}

.row-border {
  border: 1px solid #c3c5d5 !important;
}

button.rounded-xl {
  border-radius: 14px !important;
}

.v-input__details {
  display: none;
}

.v-field.v-field--focused .v-field__outline,
.v-input.v-input--error .v-field__outline {
  display: none !important;
}

.v-app-bar.v-toolbar:not(.v-toolbar--flat) {
  box-shadow: 1px 0 20px #0000000b !important;
}

html .elevation-24 {
  box-shadow: 1px 0 20px #0000000b !important;
}

html .card {
  border: none !important;
}

.elevation-10 {
  box-shadow: 1px 0 20px #0000000b !important;
}

#app {
  font-family: "Roboto", sans-serif;
}

.v-data-table
  .v-table__wrapper
  > table
  > thead
  > tr
  > th:not(.v-data-table__th--sorted)
  .v-data-table-header__sort-icon,
.v-data-table
  .v-table__wrapper
  > table
  tbody
  > tr
  > th:not(.v-data-table__th--sorted)
  .v-data-table-header__sort-icon {
  opacity: 0.2 !important;
}

#mediaOutlets .v-data-table-footer {
  display: none !important;
}

#mediaOutlets .v-data-table__tr {
  cursor: pointer !important;
}

/* new tables */
/* table css */
.v-table .v-table__wrapper > table > thead > tr > th {
  border-bottom: 0px solid #fff !important;
}

thead > tr > th:first-child {
  padding-left: 30px !important;
}

thead > tr > th:last-child {
  padding-right: 30px !important;
}

/* end of table css */
.v-field--variant-filled .v-field__overlay {
  border-radius: 20px !important;
}

.v-field__outline {
  --v-field-border-width: 0;
}

.v-field--active {
  --v-field-border-width: 0 !important;
  border: 0 !important;
}
</style>
